import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import Helmet from 'react-helmet';
import { graphql } from 'gatsby';

// Layout component
import LayoutWithVideoBanner from '../components/layouts/LayoutWithVideoBanner';
import CustomersLogos from '../components/CustomersLogos';
import ContactBanner from '../components/ContactBanner';
import ArticleCard from '../components/ArticleCard';
import SectionOnlyFooter from '../components/SectionOnlyFooter';

const PressLogosLayout = styled.div`
    display: flex;
    flex-wrap: wrap;
    margin:8% 0%;
    align-items:center;
    justify-content:center;
    box-sizing:border-box;
`;

const Logo = styled.img`
    width:13em;
    margin:15px;
    flex-grow: 0;

    @media (max-width: 767px) {
        width:8em;
    }
`;

const ArticleCardContainer = styled.div`
    margin: 7% auto;
`;

const TitlePress = styled.h3`
    font-size: 30px;
    font-weight: 200;
`;

const LayoutArticleCard = styled.div`
    display:flex;
    flex-direction:row;
    flex-wrap:wrap;
    justify-content:space-between;
    margin-top:5%;
`;

const IndexPage = ({ data }) => {
    const { t } = useTranslation('pressPage');

    return (
        <React.Fragment>
            <Helmet defer={false}>
                <title>{t('META_TITLE_PRESS')}</title>
                <meta name="description"
                    content={t('META_DESCRIPTION_PRESS')}/>
            </Helmet>

            <LayoutWithVideoBanner>
                <div className='wemap-page-index'>
                    <h1 className='wemap-page-index-title'
                        dangerouslySetInnerHTML={{ __html: t('TITLE') }}></h1>

                    <SectionOnlyFooter
                        titleSection={t('TITLE_DOWNLOADS')}
                        cellParagraph={t('DESCRIPTION_DOWNLOADS')}
                        pictureRight={data.ipad.childImageSharp}
                        altDescription='iPad with Wemap map'
                        titleButton={t('TITLE_DOWNLOADS')}
                        widthPicture='400px'
                        urlFooter='/images/press/press_kit_wemap.zip'
                    />

                    <ArticleCardContainer>
                        <TitlePress>{t('TITLE_CP')}</TitlePress>

                        <LayoutArticleCard>
                            <ArticleCard
                                iconArticle='/images/press/kiosk.svg'
                                date={t('DATE_JCDECAUX')}
                                title={t('DESCRIPTION_JCDECAUX')}
                                downloadLink={{
                                    url: '/images/press/jc-decaux-colmar-histoire-de-la-cite.pdf',
                                    text: t('TITLE_DOWNLOADS')
                                }}
                                articleLink={{
                                    url: 'https://www.lesechos.fr/pme-regions/grand-est/a-colmar-le-parcours-historique-a-ete-enrichi-1335018',
                                    text: t('TITLE_ARTICLE')
                                }}
                                arrowPicture='/images/press/white-arrow.svg'
                            />
                            <ArticleCard
                                iconArticle='/images/press/book.svg'
                                date={t('DATE_QHA')}
                                title={t('DESCRIPTION_QHA')}
                                downloadLink={{
                                    url: '/images/press/CP-Wemap_Unique-Heritage-Media_QHA.pdf',
                                    text: t('TITLE_DOWNLOADS')
                                }}
                                articleLink={{
                                    url: 'https://www.sortiraparis.com/enfant-famille/articles/256612-paris-region-aventures-le-jeu-de-piste-gratuit-pour-les-familles-devoile-de-nouv',
                                    text: t('TITLE_ARTICLE')
                                }}
                                arrowPicture='/images/press/white-arrow.svg'
                            />
                            <ArticleCard
                                iconArticle='/images/press/music.svg'
                                date={t('DATE_MUSIC')}
                                title={t('DESCRIPTION_MUSIC')}
                                downloadLink={{
                                    url: '/images/press/wemap-fete-de-la-musique.pdf',
                                    text: t('TITLE_DOWNLOADS')
                                }}
                                arrowPicture='/images/press/white-arrow.svg'
                            />
                            <ArticleCard
                                iconArticle='/images/press/compass.svg'
                                date={t('DATE_MAP')}
                                title={t('DESCRIPTION_MAP')}
                                downloadLink={{
                                    url: '/images/press/wemap_realite_augmentee_cartographie.pdf',
                                    text: t('TITLE_DOWNLOADS')
                                }}
                                arrowPicture='/images/press/white-arrow.svg'
                            />
                            <ArticleCard
                                iconArticle='/images/press/earth.svg'
                                date={t('DATE_BEGIN')}
                                title={t('DESCRIPTION_BEGIN')}
                                downloadLink={{
                                    url: '/images/press/wemap-2016.pdf',
                                    text: t('TITLE_DOWNLOADS')
                                }}
                                arrowPicture='/images/press/white-arrow.svg'
                            />
                        </LayoutArticleCard>
                    </ArticleCardContainer>

                    <CustomersLogos
                        title={t('TITLE_TALK_ABOUT')}
                        size='medium'
                        customersLogos={[
                            {image: '/images/press/cs.svg'},
                            {image: '/images/press/actukids.svg'},
                            {image: '/images/press/logo_le_point.svg'},
                            {image: '/images/press/usine-digitale.svg'},
                            {image: '/images/press/maddyness.svg'},
                            {image: '/images/press/logo_objectif_lr.svg'},
                            {image: '/images/press/femme-actuelle.svg'},
                            {image: '/images/press/midilibre.svg'},
                            {image: '/images/press/1001-startup.svg'},
                            {image: '/images/press/itrpress.svg'},
                            {image: '/images/press/france-3.svg'},
                            {image: '/images/press/digital-business-news.svg'},
                            {image: '/images/press/hellobiz.svg'},
                            {image: '/images/press/metropole-montpellier.svg'},
                            {image: '/images/press/alloweb.svg'},
                            {image: '/images/press/frenchweb.svg'},
                            {image: '/images/press/emploi-lr.svg'},
                            {image: '/images/press/les-echos.svg'},
                            {image: '/images/press/ladn.svg'},
                            {image: '/images/press/tom-travel.svg'},
                            {image: '/images/press/lemondeinformatique.svg'},
                            {image: '/images/press/jdn.svg'}
                        ]} />

                    <PressLogosLayout>
                        <Logo src='/images/press/metropole-montpellier-white.svg'
                            alt='' />
                        <Logo src='/images/press/occitanie-logo.svg'
                            alt='' />
                        <Logo src='/images/press/bpi_logo.svg'
                            alt='' />
                        <Logo src='/images/press/capital-factory.svg'
                            alt='' />
                    </PressLogosLayout>

                    <ContactBanner
                        icon='/images/press/ar-contact.svg'
                        title={t('TITLECONTACTBANNER')}
                        text={t('TEXTCONTACTBANNER')}
                        textButton={t('BUTTON_CONTACTUS')}
                        buttonUrl='/contact/'
                        iconButton='/images/press/arrow-right.svg'
                    />
                </div>
            </LayoutWithVideoBanner>

        </React.Fragment>
    );
};

IndexPage.propTypes = {data: PropTypes.object};

export default IndexPage;

export const query = graphql`{
  ipad: file(relativePath: {eq: "press/ipad_download_wemap.png"}) {
    childImageSharp {
      gatsbyImageData(quality: 75, width: 471, layout: FIXED)
    }
  }
}
`;
