import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const Container = styled.div`
    display:flex;
    flex-direction:column;
    align-items: flex-start;
    width: 16em;
    box-sizing: border-box;
    padding: 2%;

    @media (max-width: 320px) {
        width:8em;
    }

    @media (min-width: 321px) and (max-width: 767px) {
        width:10em;
        margin-bottom:7%;
    }
`;

const Picture = styled.img`
    width:auto;
`;

const Date = styled.p`
    font-size:13px;
`;

const Title = styled.h3`
    font-size:14px;
    font-weight:300;
    margin-top:0%;
    flex-grow: 1;

    @media (max-width: 767px) {
        font-size:14px;
    }
`;

const ArticleLink = styled.a`
    display:flex;
    flex-direction:row;

    position: relative;
    right: 0;
    transition: all .1s ease-out;

    &:hover{
        right: -12px;
    }

    @media (max-width: 767px) {
        &:hover{
            right: 0px;
            font-weight:500;
        }
    }
`;

const TitleDownload = styled.p`
    text-transform:uppercase;
    font-size:14px;
    margin-right:15px;
    color:white;
`;

const ArrowPicture = styled.img`
    @media (max-width: 320px) {
        display:none;
    }
`;

const ArticleCard = ({
    iconArticle, date, title, downloadLink, articleLink, arrowPicture
}) => {
    return (
        <React.Fragment>

            <Container>
                <Picture
                    src={iconArticle}
                    alt='icône du communiqué de presse'
                />
                <Date>{date}</Date>
                <Title>{title}</Title>

                <ArticleLink
                    href={downloadLink.url}
                    target='_blank'>
                    <TitleDownload>{downloadLink.text}</TitleDownload>
                    <ArrowPicture
                        src={arrowPicture}
                        alt='icône fleche'
                    />
                </ArticleLink>

                { articleLink && (
                    <ArticleLink
                        href={articleLink.url}
                        target='_blank'>
                        <TitleDownload>{articleLink.text}</TitleDownload>
                        <ArrowPicture
                            src={arrowPicture}
                            alt='icône fleche'
                        />
                    </ArticleLink>
                )}
            </Container>

        </React.Fragment>
    );
};

ArticleCard.propTypes = {
    iconArticle: PropTypes.string,
    date: PropTypes.string,
    title: PropTypes.string,
    downloadLink: PropTypes.object,
    articleLink: PropTypes.object,
    arrowPicture: PropTypes.string
};

export default ArticleCard;
