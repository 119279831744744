import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import LocalizedLink from './LocalizedLink';

const Container = styled.div`
    display:flex;
    flex-direction:row;
    align-items:center;
    background-color:#002B62;
    border-radius:10px;
    box-sizing: border-box;
    padding:25px 55px;
    margin:7% auto;
    flex-wrap: wrap;

    @media (max-width: 767px) {
      padding: 10%;
    }
`;

const Picture = styled.img`
    width:auto;
`;

const TextContainer = styled.div`
    width:27em;
    margin-left:5%;

    @media (max-width: 767px) {
        margin-left: auto;
    }
`;

const TitleContact = styled.h3`
    font-size:21px;
    font-weight:300;
`;

const TextContact = styled.p`
    font-size:14px;
    font-weight:200;
`;

const ButtonContact = styled(LocalizedLink)`
    box-sizing: border-box;
    display:flex;
    flex-direction:row;
    justify-content: space-between;
    background-color:#fff;
    border-radius:50px;
    padding:6px 25px;
    flex-grow: 1;
    margin-left: 8%;
    

    @media (max-width: 767px) {
        margin-left: auto;
        margin:5% auto;
    }
`;

const TextButton = styled.p`
    color:#434343;
    font-size:14px;
    font-weight:300;
`;

const Arrow = styled.img`
`;

const ContactBanner = ({
    icon, title, text, buttonUrl, textButton, iconButton
}) => {

    return (
        <React.Fragment>
            <Container>
                <Picture
                    src={icon}
                    alt='icône bleu représentant la réalité augmentée'
                />
                <TextContainer>
                    <TitleContact>
                        {title}
                    </TitleContact>
                    <TextContact>
                        {text}
                    </TextContact>
                </TextContainer>
                <ButtonContact to={buttonUrl}>
                    <TextButton>
                        {textButton}
                    </TextButton>
                    <Arrow src={iconButton}
                        alt='flèche blanche' />
                </ButtonContact>
            </Container>
        </React.Fragment>

    );
};

ContactBanner.propTypes = {
    icon: PropTypes.string,
    title: PropTypes.string,
    text: PropTypes.string,
    buttonUrl: PropTypes.string,
    textButton: PropTypes.string,
    iconButton: PropTypes.string
};

export default React.memo(ContactBanner);
