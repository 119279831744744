// eslint-disable-next-line no-unused-vars
import React from 'react';
import { I18nextProvider } from 'react-i18next';
import styled from 'styled-components';
import Helmet from 'react-helmet';
import RootLayout from './RootLayout';
import LayoutTemplate from './LayoutTemplate';
import i18n from '../../locales/i18n';
import { withPageContext } from '../../../pageContext';
import { getAlternatePath } from './../../components/LocalizedLink';


const MainLayoutContainer = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
`;

const MainLayoutVideoBackground = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 709px;
    overflow: hidden;

    @media (min-width: 320px) and (max-width: 767px) {
        height: 450px;
    }

    @media screen and (min-width: 481px) and (max-width: 768px) {
        height: 650px;
    }

    @media screen and (min-width: 1025px) and (max-width: 1279px) {
        height: 777px;
    }

`;

const MainLayoutVideoForeground = styled.div`
    width: 100%;
    height: 100%;
`;

const MainLayoutVideo = styled.video`
    position: absolute;
    top: -40px;
    left: 0;
    width: 100%;

    @media (max-width: 1029px) {
        top: 0px;
        width: 250%;
        left: -285px;
    }
`;

const MainLayoutContentContainer = styled.div`
    position: absolute;
    left: 0;
    right: 0;
    display: flex;
    flex-direction: row;
    justify-content: center;
    z-index: 1;
`;

class LayoutWithVideoBanner extends RootLayout {
    render() {
        const {
            children, pageContext
        } = this.props;
        const alternate = getAlternatePath(i18n.language, pageContext.originalPath);

        return (
            <I18nextProvider i18n={i18n}>
                <MainLayoutContainer>
                    <Helmet>
                        <html lang={i18n.language} />
                        {alternate.map((lang) => (
                            <link rel="alternate"
                                hreflang={lang.hreflang}
                                href={`https://getwemap.com${lang.href}`} />
                        ))}
                    </Helmet>
                    <MainLayoutVideoBackground>
                        <MainLayoutVideoForeground>
                            <MainLayoutVideo autoPlay
                                loop
                                muted
                                playsInline>
                                <source src='/images/home/master_video_en.mp4'
                                    type='video/mp4'></source>
                            </MainLayoutVideo>
                        </MainLayoutVideoForeground>
                    </MainLayoutVideoBackground>

                    <MainLayoutContentContainer id='outer-container'>
                        <LayoutTemplate>
                            {children}
                        </LayoutTemplate>
                    </MainLayoutContentContainer>

                </MainLayoutContainer>
            </I18nextProvider>
        );
    }
}

export default withPageContext(LayoutWithVideoBanner);
